<template>
  <div class="container">
    <PageTitle>RevAI Analytics</PageTitle>
    <div class="radio-group-container">
      <div>
        <span class="radio-label">Time Period:</span>
        <el-radio-group v-model="interval" @input="getTableData">
          <el-radio label="daily">Daily</el-radio>
          <el-radio label="monthly">Monthly</el-radio>
          <el-radio label="yearly">Yearly</el-radio>
        </el-radio-group>
      </div>
      <div>
        <span class="radio-label">Type:</span>
        <el-radio-group v-model="type" @input="getTableData">
          <el-radio label="user_count">User Count</el-radio>
          <el-radio label="req_count">Request Count</el-radio>
        </el-radio-group>
      </div>
      <div>
        <span class="radio-label">Current:</span>
        <span>{{ getCurrentRange() }}</span>
      </div>
      <div>
        <span class="radio-label">Current Count:</span>
        <span>{{ currentCount }}</span>
      </div>
    </div>
    <el-table :data="tableData">
      <el-table-column label="Date">
        <template slot-scope="scope">
          {{ getPeriod(scope.row.period_date) }}
        </template>
      </el-table-column>

      <el-table-column label="Count">
        <template slot-scope="scope">
          {{ scope.row.metric_value }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="tableDataPage"
      @current-change="getTableData"
      :total="tableDataCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import apiRevAiStats from "@/apis/rev-ai-stats";
import { instant } from "@ivy-way/material";
import { first } from "lodash";

export default {
  metaInfo() {
    return {
      title: "RevAI Analytics - " + this.CompanyName
    };
  },
  components: {
    PageTitle
  },
  computed: {
    instant() {
      return instant;
    }
  },
  data() {
    return {
      interval: "daily",
      type: "user_count",
      currentCount: 0,
      tableData: [],
      tableDataCount: 0,
      tableDataPage: 1
    };
  },
  methods: {
    resetPageAndGetTableData() {
      this.tableDataPage = 1;
      this.getTableData;
    },
    getTableData(page) {
      const currentPage = page || this.tableDataPage;

      apiRevAiStats
        .get({
          metric_type: this.type,
          period_type: this.interval,
          page: currentPage
        })
        .then(res => {
          const revAiStats = res.rev_ai_stats;

          this.currentCount = res.current_count;
          this.tableData = revAiStats.data;
          this.tableDataPage = revAiStats.current_page;
          this.tableDataCount = revAiStats.total;
        });
    },
    getPeriod(str) {
      if (this.interval === "daily") return str;

      const date = new Date(str);
      const year = date.getFullYear();

      if (this.interval === "monthly") {
        const month = date.getMonth();
        const lastDay = new Date(year, month + 1, 0)
          .toISOString()
          .split("T")[0];
        return `${str} ~ ${lastDay}`;
      }

      if (this.interval === "yearly") {
        return `${str} ~ ${year}-12-31`;
      }
    },
    getCurrentRange() {
      const now = new Date();
      const nowStr = now
        .toLocaleDateString("zh-TW", { timezone: "Asia/Taipei" })
        .replace(/\//g, "-");

      if (this.interval === "monthly" || this.interval === "yearly") {
        const year = now.getFullYear();
        const month = now.getMonth();

        let firstDay;
        if (this.interval === "monthly") {
          firstDay = new Date(year, month, 1);
        } else {
          firstDay = new Date(year, 0, 1);
        }

        if (
          now.getFullYear() === firstDay.getFullYear() &&
          now.getMonth() === firstDay.getMonth() &&
          now.getDate() === firstDay.getDate()
        ) {
          return nowStr;
        }

        const firstDayStr = firstDay
          .toLocaleDateString("zh-TW", { timezone: "Asia/Taipei" })
          .replace(/\//g, "-");
        return `${firstDayStr} ~ ${nowStr}`;
      }

      return nowStr;
    }
  },
  mounted() {
    this.getTableData();
  }
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.studentSelect ::v-deep .el-input__inner {
  cursor: text;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.radio-group-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.radio-label {
  font-weight: 600;
  margin-right: 10px;
}

.el-radio-group {
  display: inline-flex;
  align-items: center;
}

.el-radio {
  margin: 0 20px 0 0;
  padding: 5px 0;
}

.el-radio:last-child {
  margin-right: 0;
}

.studentSelect ::v-deep .el-input__inner {
  cursor: text;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
